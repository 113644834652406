<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card class="p-5 mb-5" :title="$t('NewCity')">

      <vx-card class="flex-1 mb-5">
        <div class="vx-row">
          <div style="margin: auto !important;" class="vx-col w-1/2 sm:w-1/5 md:w-1/6 xl:1/6">

          </div>
        </div>
        <div class="vx-row">

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">{{ $t('StateNameAr') }}</label>
            <v-select :get-option-label="option => $t(option[StateWithLocaleen])" class="mt-1 w-full p-0"
              v-validate="'required'" name="State" v-model="Model.admin1Name_ar"
              :reduce="admin1Name_en => admin1Name_en.admin1Name_en" :options="StateList"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" />

            <span class="text-danger text-sm" v-show="errors.has('State')">{{
              errors.first("State")
            }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-6">
            <label class="text-sm opacity-75">{{ $t('StateNameEn') }}</label>
            <v-select :get-option-label="option => $t(option[StateWithLocale])" class="mt-1 w-full p-0"
              v-validate="'required'" name="State" v-model="Model.admin1Name_en"
              :reduce="admin1Name_en => admin1Name_en.admin1Name_en" :options="StateList"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" />

            <span class="text-danger text-sm" v-show="errors.has('State')">{{
              errors.first("State")
            }}</span>

          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input class="w-full" :label="$t('CityNameAr')" v-model="Model.admin2Name_ar" />
            <span class="text-danger text-sm" v-show="errors.has('IntAddCity')">{{ errors.first("IntAddCity") }}</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input class="w-full" :label="$t('CityNameEn')" v-model="Model.admin2Name_en" />
            <span class="text-danger text-sm" v-show="errors.has('IntAddCountry')">{{ errors.first("IntAddCountry")
            }}</span>
          </div>
        </div>

        <div class="vx-row">
          <!-- <div class="vx-col sm:w-1/2 w-full mb-6">
            <label class="text-sm opacity-75">{{ $t('StateNameEn') }}</label>
            <v-select :get-option-label="option => $t(option[StateWithLocale])" class="mt-1 w-full p-0"
              v-validate="'required'" name="State" v-model="Model.admin2Name_en"
              :reduce="admin1Name_en => admin1Name_en.admin1Name_en" :options="StateList" />
            <span class="text-danger text-sm" v-show="errors.has('State')">{{
              errors.first("State")
            }}</span>

          </div> -->
        </div>
      </vx-card>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click.prevent="save_changes()">{{ $t("Submit") }}
            </vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="danger" @click="cancel">{{ $t("Cancel") }}</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moduleCountry from "@/store/countrySetting/moduleCountry.js";
import PaymentGateways_JSON from "@/views/jsonFiles/PaymentGateways.json";

export default {
  PaymentGatewaysJSON: PaymentGateways_JSON,
  data() {
    return {
      // admin2Name_en: "",
      // admin2Name_ar: "",
      // admin1Name_ar: "",
      // admin1Name_en: "",
      config: {
        dateFormat: "Y-m-d H:i ",
        enableTime: true
      },
      instructors: [],
      courseModules: [],
      StateList: [],
      Providers: [],
      selected: [],
      activePrompt: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      itemsPerPage: 10,
      isMounted: false,
      Model: {},
      ApiModel: {},
      country_not_found: false,
      alpha2Code: ""
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.Model.Flag != undefined &&
        this.Model.Country != undefined &&
        this.Model.CountryCode != undefined &&
        this.Model.SupportPhoneNo != ""
      );
    },

    Country() {
      return this.$store.state.CountryList.Country;
    },
    StateWithLocale() {
      let State = "admin1Name_en";
      if (this.$i18n.locale == "ar") {
        State = "admin1Name_en";
      }
      return State;
    },

    StateWithLocaleen() {
      let State = "admin1Name_ar";
      if (this.$i18n.locale == "ar") {
        State = "admin1Name_ar";
      }
      return State;
    },

  },

  methods: {

    cancel() {
      this.$router.push("/City");
    },
    // save_changes() {
    //   debugger;
    //   this.Model.admin0Name_en = "Yemen";
    //   this.Model.admin0Name_ar = "اليمن";
    //   this.Model.admin0Pcode = "YE";
    //   this.Model.Shape_Length = "0.2916760000000000";
    //   this.Model.Shape_Area = "0.004992";
    //   this.Model.Shape = "Polygon";
    //   this.Model.admin1Pcode = "YE";
    //   this.Model.admin2RefName=this.Model.admin2Name_en;
    //   this.Model.admin2Pcode = "YE";
    //   if (this.Model.OBJECTID != 0 || this.Model.OBJECTID != null) {
    //     this.Model.OBJECTID;
    //     this.$store
    //       .dispatch("CountryList/UpdateCity", this.Model)
    //       .then((response) => {
    //         if (response.status == 200) {
    //           debugger;
    //           this.$vs.loading.close();
    //           window.showSuccessCreate();
    //           this.$router.push({
    //             name: "City",
    //           });
    //         }
    //       })
    //       .catch(() => {
    //         window.showError();
    //       });
    //   }
    //   else {
    //     this.$store
    //       .dispatch("CountryList/addCity", this.Model)
    //       .then((response) => {
    //         if (response.status == 200) {
    //           this.$vs.loading.close();
    //           window.showSuccessCreate();
    //           this.$router.push({
    //             name: "FleetCompaniesDetails",
    //             params: { Id: response.data }
    //           });
    //         }

    //       })
    //       .catch(() => {
    //         window.showError();
    //       });
    //   }
    // }
    save_changes() {
  debugger;
  this.Model.admin0Name_en = "Saudi Arabia";
  this.Model.admin0Name_ar = "السعودية";
  this.Model.admin0Pcode = "SA";
  this.Model.Shape_Length = "0.2916760000000000";
  this.Model.Shape_Area = "0.004992";
  this.Model.Shape = "Polygon";
  this.Model.admin1Pcode = "SA";
  this.Model.admin2RefName=this.Model.admin2Name_en;
  this.Model.admin2Pcode = "SA";

  // Check if OBJECTID exists in the Model
  if (this.Model.hasOwnProperty("OBJECTID")) {
    this.$store.dispatch("CountryList/UpdateCity", this.Model)
      .then((response) => {
        if (response.status == 200) {
          debugger;
          this.$vs.loading.close();
          window.showSuccessCreate();
          this.$router.push({ name: "City" });
          this.cancel();
        }
      })
      .catch(() => {
        window.showError();
      });
  } else {
    this.$store.dispatch("CountryList/addCity", this.Model)
      .then((response) => {
        if (response.status == 200) {
          debugger;
          this.$vs.loading.close();
          window.showSuccessCreate();
          this.cancel();
                    this.$router.push({
            name: "City",
            params: { Id: response.data }
          });
        }
      })
      .catch(() => {
        debugger;
        this.cancel();
      //  window.showError();
      });
  }
}

  },

  created() {

    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    debugger;
    const OBJECTID = this.$route.params.OBJECTID;
    this.$store.dispatch("CountryList/GetCityByObject", OBJECTID)
      .then(res => {
        debugger;
        this.Model.admin1Name_ar = res.data[0].admin1Name_ar;
        this.Model.admin1Name_en = res.data[0].admin1Name_en;
        this.Model.admin2Name_en = res.data[0].admin2Name_en;
        this.Model.admin2Name_ar = res.data[0].admin2Name_ar;
        this.Model.OBJECTID = res.data[0].OBJECTID;
      })

    var Model = {};
    Model.CountryCode = "SA";
    this.$store
      .dispatch("CountryList/GetStateList", Model)
      .then(res => {
        debugger;
        this.StateList = res.data;
      })
      .catch(() => { });
    this.$store.dispatch("CountryList/fetchAllCountriesList").then(() => {
      this.Country.forEach(element => {
        if (
          element.CountryCode ==
          JSON.parse(localStorage.getItem("userInfo")).countrycode
        ) {
          this.Model.CountryCode = JSON.parse(
            localStorage.getItem("userInfo")
          ).countrycode;
        }
      });
    });
  }
};
</script>
